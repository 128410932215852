<template>
  <div class="register">
    <div class="title">
      <div class="back_icon" @click="handelBack()">
        <img src="@/assets/images/back_icon.png" alt="" />
      </div>
      注册
    </div>
    <div class="input_box">
      <div class="item">
        <input type="text" v-model="userName" placeholder="请输入用户名" />
      </div>
      <div class="item">
        <input
          type="number"
          v-model="phone"
          placeholder="请输入手机号码"
          @blur="handelBlurPhone()"
        />
      </div>
      <div class="item">
        <input
          type="number"
          v-model="verificationCode"
          placeholder="请输入验证码"
        />
        <div
          v-if="codeStatus == false"
          class="obtain"
          @click="handelVerificationCode()"
        >
          获取验证码
        </div>
        <div v-if="codeStatus" class="obtain obtain_active">{{ timeNum }}s重新获取</div>
      </div>
      <div class="item">
        <input
          type="password"
          v-model="password"
          placeholder="请输入8~24位密码"
        />
      </div>
      <div class="item">
        <input
          type="password"
          v-model="confirmPassword"
          placeholder="请二次确认密码"
        />
      </div>
      <div class="register_btn" @click="handelRegister()">注册</div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import { Toast } from "vant";
// import { getRegister, getSendCode } from "@/request/api";
export default {
  data() {
    return {
      userName: "",
      phone: "",
      verificationCode: "",
      password: "",
      confirmPassword: "",
      timeNum: 60,
      time: null,
      codeStatus: false,
      phoneCorrect: false,
      oTime: null,
    };
  },
  components: {},
  methods: {
    handelBack() {
      this.$router.go(-1);
    },

    // 验证手机号码
    handelBlurPhone() {
      let reg = /^[1][3,4,5,7,8][0-9]{9}$/;
      if (!reg.test(this.phone.trim())) {
        this.phoneCorrect = false;
        Toast(`请输入有效的手机号码!`);
        return false;
      } else {
        this.phoneCorrect = true;
      }
    },

    // 获取验证码
    handelVerificationCode() {
      if (this.phoneCorrect) {
        let param = {
          phoneNum: this.phone,
        };
        this.getSendCode(param);
      } else {
        Toast(`请输入有效的手机号码!`);
      }
    },

    // 验证是否为空
    isEmptyVal(val, type) {
      if (!val) {
        Toast(`${type}不能为空!`);
        return false;
      } else {
        return true;
      }
    },

    // 点击注册
    handelRegister() {
      if (
        this.isEmptyVal(this.userName.trim(), "用户名") &&
        this.isEmptyVal(this.phone.trim(), "手机号码") &&
        this.isEmptyVal(this.verificationCode.trim(), "验证码") &&
        this.isEmptyVal(this.password.trim(), "密码") &&
        this.isEmptyVal(this.confirmPassword.trim(), "确认密码")
      ) {
        let reg = /^[1][3,4,5,7,8][0-9]{9}$/;
        if (!reg.test(this.phone.trim())) {
          this.phoneCorrect = false;
          Toast(`请输入有效的手机号码!`);
          return false;
        } else {
          this.phoneCorrect = true;
          let param = {
            name: this.userName,
            phoneNum: this.phone,
            code: this.verificationCode,
            pwd: this.password,
            confirmPwd: this.confirmPassword,
          };
          this.getRegister(param);
        }
      }
    },

    // 注册接口
    // getRegister(param) {
    //   getRegister(param).then((res) => {
    //     console.log(res);
    //     if (res) {
    //       Toast(`注册成功!`);
    //       this.oTime = setTimeout(() => {
    //         this.$router.push("/login");
    //       }, 2000);
    //     }
    //   });
    // },

    // 发送验证码接口
    // getSendCode(param) {
    //   getSendCode(param).then((res) => {
    //     console.log(res);
    //     Toast(`已发送!`);
    //     this.time = setInterval(() => {
    //       let timeNum = this.timeNum;
    //       timeNum--;
    //       this.timeNum = timeNum;
    //       this.codeStatus = true;
    //       if (timeNum == 0) {
    //         clearInterval(this.time);
    //         this.timeNum = 60;
    //         this.codeStatus = false;
    //       }
    //     }, 1000);
    //   });
    // },
  },
};
</script>

<style scoped lang="less">
.register {
  .title {
    width: 100%;
    height: 0.88rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "PingFangSC-Regular";
    font-size: 0.34rem;
    font-weight: 600;
    color: #ffffff;
    position: fixed;
    left: 0;
    top: 0;
    background-color: #0067b0;
  }

  .back_icon {
    position: absolute;
    left: 0.45rem;
    top: 50%;
    transform: translateY(-50%);
  }

  .back_icon img {
    width: 0.19rem;
    height: 0.34rem;
  }

  .input_box {
    width: 100%;
    padding: 0 0.3rem;
    margin-top: 1.09rem;

    .item {
      border-bottom: 0.01rem solid #e5e5e5;
      padding: 0.3rem 0;
      margin-bottom: 0.1rem;
      position: relative;

      input {
        font-size: 0.32rem;
        font-family: "PingFangSC-Regular";
        font-weight: 400;
        color: #333333;
        width: 100%;
        font-size: 0.32rem;
      }

      &::-webkit-input-placeholder {
        color: #999999;
      }
    }
  }

  .input_box .obtain {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    font-size: 0.28rem;
    font-family: "PingFangSC-Regular";
    font-weight: 400;
    color: #0067B0;
    padding: 0.1rem 0.06rem;
    border: 0.01rem solid #0067B0;
    border-radius: 0.6rem;
  }

  .input_box .obtain_active {
    color: #bbbbbb;
    border: 0.01rem solid #bbbbbb;
  }

  .register_btn {
    width: 100%;
    height: 0.9rem;
    background: #0067b0;
    border-radius: 0.45rem;
    text-align: center;
    line-height: 0.9rem;
    font-size: 0.34rem;
    font-family: "PingFangSC-Regular";
    font-weight: 400;
    color: #ffffff;
    margin-top: 1.5rem;
  }
}
</style>
